<template>
  <div class="top">
    <div
      class="banner"
      :style="{ backgroundImage: 'url(' + this.seller_background + ')' }"
    >
      <div
        class="thumbnail"
        :style="{ backgroundImage: 'url(' + this.seller_thumbnail + ')' }"
      ></div>
    </div>
    <div class="info">
      <div class="name">{{ this.seller_name }}</div>
      <div class="desc">{{ this.seller_desc }}</div>
    </div>
  </div>
  <List
    :data="product_data"
    :count="product_count"
    :seller_id="seller_id"
    :data_fully_loaded="data_fully_loaded"
    @load_more="this.load_product_data"
    @change_sort="this.change_sort"
    @change_filter="this.change_filter"
  ></List>
  <Spinner v-show="processing" />
</template>

<script>
import List from "@/views/product/List.vue";
export default {
  name: "Store",
  components: {
    List,
  },
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.seller_name,
      og: {
        title: this.$t("meta.title") + " :: " + this.seller_name,
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      seller_id: 0,
      seller_name: "",
      seller_desc: "",
      seller_thumbnail: "",
      seller_background: "",
      product_data: [],
      product_count: 0,

      data_fully_loaded: false,
      search_page: 1,
      search_sorting: {},
      search_filter: {},
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.seller_id = parseInt(this.$route.params.seller_id);
    this.load_seller_data();
    this.load_product_data();
  },
  methods: {
    load_seller_data: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/seller/get",
          {
            seller_id: self.seller_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data.data;
          self.seller_name = data.seller_name;
          self.seller_desc = data.seller_desc;

          if (data.seller_thumbnail != null && data.seller_thumbnail != "") {
            self.seller_thumbnail =
              process.env.VUE_APP_API_URL +
              "/resource/get/seller/" +
              self.seller_id +
              "/" +
              data.seller_thumbnail;
          }

          if (data.seller_background != null && data.seller_background != "") {
            self.seller_background =
              process.env.VUE_APP_API_URL +
              "/resource/get/seller/" +
              self.seller_id +
              "/" +
              data.seller_background;
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //!-- axios
    }, //-- load_seller_data

    load_product_data: function () {
      if (this.data_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/product/list",
          {
            seller_id: self.seller_id,
            filter: self.search_filter,
            sorting: self.search_sorting,
            page: self.search_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (self.search_page <= 1) {
            self.product_data = [];
          }

          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            self.product_data.push(data[i]);
          }

          if (data.length <= 0) {
            self.data_fully_loaded = true;
          } else {
            self.search_page++;
          }

          // 총 갯수 정보 헤더에서 가져오기
          try {
            let str_info = response.headers.wkt_extra_info;
            let extra_info = JSON.parse(str_info);
            self.product_count = parseInt(extra_info.total_count);
          } catch (e) {
            console.log(e);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //!-- axios
    }, //-- load_product_data

    change_sort: function (sorting) {
      this.search_sorting = sorting;

      this.data_fully_loaded = false;
      this.search_page = 1;

      this.load_product_data();
    }, //-- change_sort

    change_filter: function (filter) {
      this.search_filter = filter;

      this.data_fully_loaded = false;
      this.search_page = 1;

      this.load_product_data();
    },
  },
};
</script>

<style scoped>
.top {
  position: relative;
  border-bottom: 1px solid #dedede;
}
.top > .banner {
  position: relative;
  height: 260px;
  background-color: #dedede;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
}

.top > .banner > .thumbnail {
  position: absolute;
  right: 50%;
  bottom: 0;
  transform: translate(50%, 50%);
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
}
.top > .info {
  position: relative;
  margin-top: 80px;
  padding: 0 0 4rem 0;
}
.top > .info > .name {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 1rem 0 0 0;
  text-align: center;
}
.top > .info > .desc {
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0.4rem 0 0 0;
  color: #808080;
  text-align: center;
}
</style>
